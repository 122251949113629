import {CheckoutNavigationService, ModalType} from '@wix/wixstores-client-storefront-sdk';
import {CreateCheckoutExceptions} from '../../common/constants';
import {getAdditionalFeesPrice, getCatalogAppIds, getNumberOfAdditionalFees} from './bi.utils';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {CartModel} from '../models/Cart.model';

export const handleCreateCheckoutErrors = async (
  checkoutNavigationService: CheckoutNavigationService,
  isEditorX: boolean,
  cartModel: CartModel,
  estimatedTotals: EstimatedTotalsModel,
  createCheckoutException: CreateCheckoutExceptions,
  stopSendingLostBusinessEmail: boolean
): Promise<void> => {
  /* istanbul ignore else */
  if (createCheckoutException === CreateCheckoutExceptions.siteMustAcceptPayments) {
    if (!stopSendingLostBusinessEmail) {
      checkoutNavigationService.sendLostBusinessEmail(false);
    }
    const destination = cartModel.contactInfo.address;
    const catalogAppId = getCatalogAppIds(cartModel);
    const additionalFeesPrice = getAdditionalFeesPrice(estimatedTotals);
    const additionalFeesNumber = getNumberOfAdditionalFees(estimatedTotals);
    await checkoutNavigationService.openLegacyCartModal(
      ModalType.NoOnlinePayments,
      {destination, catalogAppId, additionalFeesPrice, additionalFeesNumber},
      isEditorX
    );
  }
};
